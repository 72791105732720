import { navigate } from "gatsby"
import React from "react"
import { Dropdown, Form } from "react-bootstrap"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: end;
  margin: 15px 30px 0 0;

  .dropdown {
    /* width: 75px !important;
    button {
      width: 100% !important;
      font-size: 12px !important;
    } */
  }
`

const Translation = ({ pageContext }) => {
  const links = pageContext?.alternativeLocales && Object.entries(pageContext?.alternativeLocales).map(e => {
    return {
      locale: e[0],
      url: e[1],
    }
  })

  const handleNavigation = e => {
    const {value} = e.target
    navigate(value)
  }


  return (
    <Container>
      {links?.length > 1 && (
        <Form.Select
          aria-label="Default select example"
          onChange={handleNavigation}
          size="sm"
          style={{ width: "unset" }}
        >
          <option>{pageContext?.locale?.toUpperCase()}</option>

          {links.map(item => {
            if (item.url !== pageContext.url) {
              return (
                <option value={item.url}>{item.locale?.toUpperCase()}</option>
              )
            }
          })}
        </Form.Select>
      )}
    </Container>
  )
}

export default Translation
